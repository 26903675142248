html {
  position: relative;
  min-height: 100%;
}

.pad10 {
  padding: 10px;
  display: block;
}
.pad20 {
  padding: 20px;
  display: block;
}
.pad30 {
  padding: 30px;
  display: block;
}
.pad40 {
  padding: 40px;
  display: block;
}
.pad50 {
  padding: 50px;
  display: block;
}
.marg20 {
  margin: 20px;
  display: block;
}

.marright {
  margin-right: 10px;
}
.martop {
  margin-top: -10px;
}
.spacer2 {
  height: 10px;
  width: 100%;
}
.spacer3 {
  height: 5px;
  width: 100%;
}
.spacer {
  height: 20px;
  width: 100%;
}
.spacer5 {
  height: 50px;
  width: 100%;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.cursorpointer {
  cursor: pointer;
}

.p-inputtext {
  padding: 0.25em 0.5em !important;
}
.p-corner-top {
  border-radius: 0px !important;
}
.btn {
  border-radius: 0px !important;
  border: none !important;
}
.clear {
  clear: both;
}
.bnone {
  font-weight: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #cccccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccccc;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #cccccc;
}

.pi-chevron-down:before {
  content: '\e906' !important;
}
